<template>
  <div class="app position-relative h-100">
    <loading-overlay v-if="checkingLogin" />
    <div v-else>
      <navigation v-if="!HideNavigation" class="sticky top-0 z-50" />
      <router-view />
    </div>
  </div>
</template>

<script>
const Navigation = () => import('@/components/navigation/Navigation.vue');
const LoadingOverlay = () => import('@/components/LoadingOverlay.vue');

export default {
  name: 'App',
  components: {
    Navigation,
    LoadingOverlay,
  },
  computed: {
    HideNavigation() {
      return this.$route.name === 'SignIn' || this.$route.name === 'SignOut';
    },
  },
  data() {
    return {
      account: this.$store.state.account,
      checkingLogin: false,
      noRedirectRoutes: [
        'SignIn',
        'Candle',
      ],
    };
  },
  methods: {
    redirectPage() {
      if (this.$route.name === null) {
        setTimeout(() => {
          this.redirectPage();
        }, 100);
        return;
      }

      if (!this.noRedirectRoutes.includes(this.$route.name)) {
        const query = {
          redirect: location.pathname,
        };
        this.$router.push({ name: 'SignIn', query }, () => {}, () => {});
      }
    },
    checkLogin() {
      if (this.checkingLogin) return;
      this.checkingLogin = true;
      this.$http
        .get('/account/check_login')
        .then((res) => {
          if ('account' in res.body) {
            this.account = Object.assign(this.account, res.body.account);
            if (this.HideNavigation) {
              this.$router.push(this.$store.state.app.defaultRoute);
            }
          }
        })
        .catch(() => {
          this.redirectPage();
        })
        .finally(() => {
          this.checkingLogin = false;
        });
    },
  },
  created() {
    this.checkLogin();
    this.$store.dispatch('fetchCurrencies');
    this.$store.dispatch('fetchDropdowns');
  },
  watch: {
    $route(to, from) {
      if (to.name === 'SignIn' && from.name !== null && from.name !== 'SignOut' && from.name !== 'SignIn') {
        const query = {
          redirect: from.path,
        };
        this.$router.replace({ query }, () => {}, () => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
